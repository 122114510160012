import { Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";

export const TermsConditionsSection = ({
  productsState,
  submitToShoppingCart,
  cardSubmissonPendingStateObj,
  activeModalSectionStateObj,
  modelHeaderStateObj,
}) => {
  const { handleSubmit, register, watch } = useForm();

  const handleFormSubmit = async () => {
    if (typeof submitToShoppingCart !== "function") {
      console.error("submitToShoppingCart is not a function");
      return;
    }
    await submitToShoppingCart();
  };
  return (
    <>
      <Modal.Header>
        <Modal.Title className="mx-auto text-uppercase">{modelHeaderStateObj.state}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center">
        <p>Current production time for custom orders:</p>
        <h3 className="font-weight-bold">{productsState?.activeObj?.shopify?.productionTime ?? `2 to 3 weeks`}</h3>
        <p>
          by clicking below you agree to our{" "}
          <a href="https://thrillseekers.com/policies/terms-of-service" target="_blank" rel="noreferrer">
            terms of service
          </a>{" "}
          regarding all custom products. Please double check all options on your custom seat cover! NO CHANGES OR CANCELLATIONS may be made to orders after
          checkout.
        </p>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <label className="fw-bold text-danger">
            <input className="mx-1" name="acceptTerms" type="checkbox" {...register("acceptTerms", { required: true })} />I understand that my order cannot be
            changed at all or canceled
          </label>
          <br />
          <div className="d-flex justify-content-center mt-2">
            <button type="button" className="mx-2 px-2" style={{ backgroundColor: "grey" }} onClick={() => activeModalSectionStateObj.setState("additionals")}>
              Go Back
            </button>
            <button className="mx-2 px-2 submitBtn" disabled={!watch("acceptTerms") || cardSubmissonPendingStateObj.state} type="submit">
              I Agree
              {cardSubmissonPendingStateObj.state && (
                <Spinner
                  animation="border"
                  style={{
                    width: "inherit",
                    height: "inherit",
                    verticalAlign: "0",
                    marginLeft: "5px",
                  }}
                />
              )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </>
  );
};
